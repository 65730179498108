// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getLastPartOfURL,
  sendAPIRequest,
} from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface Props {
  navigation: any;
  id: string;
  stripe: any;
  elements: any;
}

interface ICardDetails {
  id: string;
  type: string | null;
  name: string | null;
  last4: string | null;
  expiry_date: string | null;
}

interface SProps {
  isOnboardingDone: boolean;
  isAddCardFormOpen: boolean;
  isLoading: boolean;
  savedCardList: ICardDetails[];
  errorMsg: string;
  successMsg: string;
  accountStatus: null | string;
  successTimer: number;
}

interface SSProps {
  ssId: string;
}
// Customizable Area End

export default class SettingsPaymentController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  // Customizable Area Start
  timerId: any = null;
  userDetailsApiCallId: string = "";
  fetchCardListApiCallId: string = "";
  addCardApiCallId: string = "";
  removeCardApiCallId: string = "";
  hostStripeApiCallId: string = "";
  clearErrorMsgsTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      isOnboardingDone: false,
      isAddCardFormOpen: false,
      isLoading: false,
      savedCardList: [],
      errorMsg: "",
      successMsg: "",
      accountStatus: null,
      successTimer: 3, // In seconds
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.fetchUserDetails();
    setTimeout(() => {
      if (
        !this.state.isOnboardingDone &&
        new URL(document.baseURI).searchParams.get("status") === "done"
      ) {
        this.fetchUserDetails();
        this.setState({ accountStatus: null });
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "HostPaymentsSetting"
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      }
    }, 15000);
    if (getLastPartOfURL().role !== "host") this.fetchSavedCardList();
    this.setState({
      accountStatus: new URL(document.baseURI).searchParams.get("status"),
    });
  }

  componentDidUpdate(prevProps: Props, prevState: SProps) {
    if (
      getLastPartOfURL().role === "host" &&
      this.state.accountStatus === "done" &&
      this.state.isOnboardingDone &&
      this.state.successTimer &&
      !this.timerId
    ) {
      this.timerId = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.successTimer > 1) {
            return { ...prevState, successTimer: prevState.successTimer - 1 };
          } else {
            const msg = new Message(getName(MessageEnum.NavigationMessage));
            msg.addData(
              getName(MessageEnum.NavigationTargetMessage),
              "HostPaymentsSetting"
            );
            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );
            this.send(msg);
            return { successTimer: 0, accountStatus: null };
          }
        });
      }, 1000);
    }
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.userDetailsApiCallId]: this.handleUserDetailsAPIResponse,
      [this.fetchCardListApiCallId]: this.handleAPIResponse,
      [this.addCardApiCallId]: this.handleAddCardResponse,
      [this.removeCardApiCallId]: this.handleRemoveCardAPIResponse,
      [this.hostStripeApiCallId]: this.handleHostStripeAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  fetchUserDetails = async () => {
    const token = await getStorageData("token");
    this.userDetailsApiCallId = sendAPIRequest(
      "bx_block_settings/profile_settings/view",
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  fetchSavedCardList = async () => {
    this.setState({ isLoading: true });
    const token = await getStorageData("token");
    this.fetchCardListApiCallId = sendAPIRequest(
      "bx_block_stripe_integration/payment_methods",
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  addNewCard = async (token: { id: string }, card_holder: string) => {
    this.setState({ isLoading: true });
    const authToken = await getStorageData("token");
    this.addCardApiCallId = sendAPIRequest(
      "bx_block_stripe_integration/payment_methods",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
        body: {
          payment_method: {
            token: token.id,
            card_holder,
          },
        },
      }
    );
  };

  removeCardById = async (id: string) => {
    this.setState({ isLoading: true });
    const token = await getStorageData("token");
    this.removeCardApiCallId = sendAPIRequest(
      `bx_block_stripe_integration/payment_methods/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  getOnboardingAccountData = async () => {
    this.setState({ isLoading: true });
    const token = await getStorageData("token");
    this.hostStripeApiCallId = sendAPIRequest(
      "bx_block_stripe_integration/payment_methods/account_onboarding",
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handleUserDetailsAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: {
        id: string;
        type: string;
        attributes: {
          onboarding_done: boolean;
        };
      };
    };
    if (response.data) {
      this.setState({
        isOnboardingDone: response.data.attributes.onboarding_done,
      });
    }
  };

  handleAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: {
        id: string;
        type: string;
        attributes: {
          billing_details: { name: string };
          card: {
            last4: string;
            brand: string;
            exp_month: number;
            exp_year: number;
          };
        };
      }[];
    };
    if (response.data) {
      this.setState({
        savedCardList: response.data.map((cardItem) => {
          return {
            id: cardItem.id,
            name: cardItem.attributes.billing_details.name,
            last4: cardItem.attributes.card.last4,
            type: cardItem.attributes.card.brand,
            expiry_date:
              String(cardItem.attributes.card.exp_month).padStart(2, "0") +
              "/" +
              cardItem.attributes.card.exp_year,
          };
        }) as ICardDetails[],
      });
    }
  };

  handleRemoveCardAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleDeleteErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      message: string;
    };
    if (response.message) {
      this.fetchSavedCardList();
      this.setState({
        successMsg: response.message,
      });
    }
  };

  handleHostStripeAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleDeleteErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      object: string;
      created: number;
      expires_at: number;
      url: string;
    };
    if (response.url) {
      window.open(response.url, "_self");
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });
    const { errors } = responseJSON as {
      errors: { stripe: string }[];
    };
    if (errors) {
      this.setState({ errorMsg: errors[0].stripe });
      return true;
    }
    return false;
  };

  handleDeleteErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { error } = responseJSON as {
      error: string;
    };
    if (error) {
      this.setState({ errorMsg: error, isLoading: false });
      return true;
    }
    return false;
  };

  handleAddCardResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: object }[];
      meta?: { message: string };
    };
    if (response.data && response.meta) {
      this.fetchSavedCardList();
      this.setState({
        successMsg: "Congratulations, " + response.meta.message,
      });
      this.handleReset();
    }
  };

  clearMsgs = () => {
    if (this.clearErrorMsgsTimeout) {
      clearTimeout(this.clearErrorMsgsTimeout);
    }
    this.clearErrorMsgsTimeout = setTimeout(() => {
      this.setState({ errorMsg: "" });
      this.handleSuccessMsgClose();
    }, 5000);
  };

  handleReset = () => {
    this.setState({
      errorMsg: "",
      isLoading: false,
      isAddCardFormOpen: false,
    });
  };

  handleAddCardFormOpen = () => {
    this.setState({ isAddCardFormOpen: true });
  };

  handleSuccessMsgClose = () => {
    this.setState({ successMsg: "" });
  };

  getTitle = () => {
    return getLastPartOfURL().role === "host"
      ? configJSON.hostPaymentMethodsText
      : configJSON.paymentMethodsText;
  };

  getInfoText = () => {
    return getLastPartOfURL().role === "host"
      ? configJSON.hostPaymentMethodInfoText
      : configJSON.paymentMethodInfoText;
  };

  getAddBtnText = () => {
    const stripeSetupBtn = this.state.isOnboardingDone
      ? configJSON.editStripAccountText
      : configJSON.setUpStripAccountText;
    const hostButtonText = this.state.isLoading
      ? configJSON.redirectingText
      : stripeSetupBtn;
    return getLastPartOfURL().role === "host"
      ? hostButtonText
      : configJSON.addPaymentMethodBtnText;
  };

  getSaveBtnText = () => {
    return this.state.isLoading
      ? configJSON.addingPaymentMethodBtnText
      : configJSON.addPaymentMethodBtnText;
  };

  getSuccessMsg = () => {
    return (
      getLastPartOfURL().role === "host" &&
      this.state.isOnboardingDone &&
      "Congratulations, Stripe setup already completed."
    );
  };
  // Customizable Area End
}
