import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface Favorite {
  id: number;
  latitude: string;
  longitude: string;
  address: string | null;
  docking_length: number;
  docking_width: number;
  listing_title: string;
  about: string;
  access: string;
  parking_space: number;
  max_boats: number;
  max_guests: number;
  allow_pets: boolean;
  allow_smoking: boolean;
  allow_liveaboards: boolean;
  rules_and_cautions: string;
  has_security_camera: boolean;
  has_animals: boolean;
  reservation_type: string;
  cancellation_policy: string;
  base_price: string;
  locality: string | null;
  administrative_area_level_1: string | null;
  administrative_area_level_2: string | null;
  state_short_name: string | null;
  step: number;
  features: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  }[];
  account_id: number;
  dock_type: string;
  listing_type: string;
  water_depth: number;
  rental_reason: string | null;
  lake: { id: number; name: string; state: string } | null;
  images: string[] | null;
  dock_add_ons: {
    id: number,
    add_on_id: number,
    dock_id: number,
    price: number
  }[] | null;
  service_fee: number;
  guest_total: string;
  prices: {
    per_day: string;
    per_Week: string;
    per_month: string;
  };
  host: {
    name: string;
    reviews: number;
    rating: number;
    avatar: string | null;
  };
  blocked_dates: {};
}
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {   
    // Customizable Area Start
    isDeleteDialogOpen: boolean,
    favorites: {id: number; dock_listing: Favorite}[] | null,
    selectedFavourite: {id: number, dock_listing: Favorite},
    errorMsg: string,
    current_page: number;
    total_count: number;
    total_pages: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class FavouritesController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    itemsPerPage: number = 20;
    fetchFavoritesApiCallId: string = "";
    removeFromFavoritesApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.NavigationMessage),
          getName(MessageEnum.NavigationPropsMessage),
          getName(MessageEnum.NavigationTargetMessage),
          getName(MessageEnum.SessionSaveMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        isDeleteDialogOpen: false,
        favorites: null,
        selectedFavourite: {id: -1, dock_listing: {} as Favorite},
        errorMsg: "",
        current_page: 1,
        total_count: 0,
        total_pages: 0,
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.apiSuccessCallBackController(apiRequestCallId, responseJson);
      // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
      super.componentDidMount();
      this.fetchFavorites();
    }

    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: Record<string, unknown>
    ) => {
      const successCallbackMap = {
        [this.fetchFavoritesApiCallId]: this.handleFavoritesAPIResponse,
        [this.removeFromFavoritesApiCallId]: this.handleRemoveFavoritesAPIResponse,
      };

      if (apiRequestCallId) {
        const successCallback: (responseJSON: Record<string, unknown>) => void =
          successCallbackMap[apiRequestCallId];
        !!successCallback && successCallback(responseJSON);
      }
    };

    fetchFavorites = async () => {
      this.setState({ errorMsg: "" });
      const token = await getStorageData("token");
      this.fetchFavoritesApiCallId = sendAPIRequest(
        `bx_block_favourites/favourites?page=${this.state.current_page}&per=${this.itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };

    removeFromFavorites = async () => {
      this.setState({ errorMsg: "" });
      const token = await getStorageData("token");
      this.removeFromFavoritesApiCallId = sendAPIRequest(
        `bx_block_favourites/favourites/${this.state.selectedFavourite.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };

    handleFavoritesAPIResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) return;
      const response = responseJSON as {
        data?: { id: string; type: string; attributes: { id: number, dock_listing: any } }[];
        meta?: { pagination: { total_count: number; total_pages: number } };
      };
      if (response.data && response.meta) {
        const favorites = response.data.map((favorite) => favorite.attributes);
        this.setState({
          favorites,
          total_count: response.meta.pagination.total_count,
          total_pages: response.meta.pagination.total_pages,
        });
      } else {
        this.setState({ favorites: [] });
      }
    };

    handleRemoveFavoritesAPIResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) return;
      const response = responseJSON as {
        message?: string;
      };
      if (response.message) {
        this.setState({ isDeleteDialogOpen: false, current_page: 1 });
        this.fetchFavorites();
      }
    };

    handleErrorResponse = (responseJSON: Record<string, unknown>) => {
      const { errors } = responseJSON as { errors: string[] };
      if (errors) {
        this.setState({ errorMsg: errors[0] });
        return true;
      }
      return false;
    };

    handleDeleteClick = (selectedFavourite: {id: number, dock_listing: Favorite}) => {
      this.setState({
        errorMsg: "",
        isDeleteDialogOpen: true,
        selectedFavourite,
      });
    }

    onCloseDialog = () => {
      this.setState({ isDeleteDialogOpen: false });
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
      this.setState({
        current_page: value,
      }, () => this.fetchFavorites());
    };

    handleClickCard = (id: number) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'DockBooking');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      this.send(message)
    }
    // Customizable Area End
  }
