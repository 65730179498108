import React from "react";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  AppBar,
  Tabs,
  Tab,
  Avatar,
  IconButton,
  Drawer,
  Divider,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
} from "@material-ui/core";
import ArrowDropDownOutlined from "@material-ui/icons/ArrowDropDownOutlined";
import { imgHeaderLogo, userLogo } from "./assets";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import {
  getLastPartOfURL,
  Continue,
  MustVerifyEmail,
  MustCompleteProfile,
} from "./utils";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { v4 as uuidv4 } from "uuid";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import Login from "../../blocks/email-account-login/src/Login.web";
import Signup from "../../blocks/email-account-registration/src/Signup.web";
import ForgotModal from "../../blocks/forgot-password/src/ForgotModal.web";
import {
  getStorageData,
  removeStorageData,
} from "../../framework/src/Utilities";
import { SvgIconComponent } from "@material-ui/icons";
import jwtDecode from "jwt-decode";

export enum OpenModal {
  None = 1,
  SignUp,
  Login,
  ForgotPassword,
}

interface Props {
  navigation: any;
  role?: string;
  isLoggedIn?: boolean;
}

interface IProfileMenu {
  value: string;
  label: string;
  icon: SvgIconComponent;
};

interface State {
  userProfileData:{id:number; first_name:string; last_name: string; profile_pic:string};
  isDrawerOpen: boolean;
  tabList: { value: string; label: string }[];
  boaterTabList: { value: string; label: string }[];
  anchorElUserProfileMenu: null | HTMLElement;
  openModal: OpenModal;
  token: string;
  tempToken: string;
  email: string;
  password: string;
  continueFromLogin: Continue;
  userMenu: IProfileMenu[];
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
    }
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans",
      },
      body1: {
        color: "#0F172A",
        fontSize: "1.1rem",
        fontFamily: "Outfit",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Outfit, sans-serif",
        color: "#1275e4",
        borderColor: "#1275e4 !important",
        textTransform: "none",
        margin: "5px",
      },
      contained: {
        backgroundColor: "#1275e4",
        margin: "5px",
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: "#FFFFFF",
          color: "#1275e4",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#4f9fff",
      },
      scrollButtons: {
        color: "#334155",
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: "#4f9fff",
        },
        width: "max-content !important",
        minWidth: "max-content !important",
        fontFamily: "Outfit",
        fontSize: "1rem",
        fontWeight: 700,
        color: "#334155",
      },
      textColorPrimary: {
        "&$selected": {
          color: "#4f9fff",
        },
        fontFamily: "Outfit",
        fontSize: "1rem",
        fontWeight: 700,
        color: "#334155",
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "8px",
        marginTop: "52px",
        minWidth: 192,
        color: "#000000",
        boxShadow: "#F1F5F9 0px 8px 32px 0px, #000000 0px 4px 5px 0px",
        "& li": {
          margin: "2px",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#FDDA96",
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        height: "auto",
      },
      list: {
        padding: "4px !important",
        width: "auto !important",
      },
    },
    MuiMenuItem: {
      root: {
        padding: "9px 20px 9px 16px !important",
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "fill",
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: 5,
        fontFamily: "Outfit",
        fontSize: "1rem"
      }
    },
  },
});

const Wrapper = styled("div")(({ theme }) => ({
  "& .headerWrapper": {
    width: "100%",
    height: "auto",
    backgroundColor: "#FFFFFF",
    padding: "10px 0",
  },
  "& .toolBarWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .logoImg": {
    width: "20%",
    height: "auto",
    minWidth: "175px",
    cursor: "pointer",
  },
  "& .textCapitalize": { textTransform: "capitalize" },
  "& .buttonWrapper": {
    padding: "2% 0%",
  },
  "& .button": {
    maxWidth: "200px",
    height: "44px",
    margin: "5px",
    fontWeight: 700,
  },
  "& .tabVisible": {
    display: "flex",
  },
  "& .menuIconVisible": {
    display: "none",
  },
  "& .userProfileMenu": {
    top: "80px",
  },
  [theme.breakpoints.down(1156)]: {
    "& .textCapitalize": {
      fontSize: "0.9rem",
    },
    "& .buttonWrapper": {
      padding: "0",
    },
    "& .button": {
      fontSize: "0.9rem",
      display: "flex",
    },
  },
  [theme.breakpoints.down(1115)]: {
    "& .button": {
      padding: "5px",
    },
  },
  [theme.breakpoints.down(1064)]: {
    "& .textCapitalize": {
      fontSize: "0.9rem",
    },
    "& .button": {
      fontSize: "0.8rem",
    },
  },
  [theme.breakpoints.down(1030)]: {
    "& .tabVisible": { display: "block" },
  },
  [theme.breakpoints.down(960)]: {
    "& .button": {
      fontSize: "0.8rem",
    },
  },
  [theme.breakpoints.down(870)]: {
    "& .tabVisible": {
      display: "none",
    },
    "& .menuIconVisible": {
      display: "block",
    },
  },
  [theme.breakpoints.down(540)]: {
    "& .buttonWrapper": {
      flexDirection: "column",
    },
    "& .button": {
      width: "-moz-available",
    },
  },
}));

export default class Header extends React.Component<Props, State> {
  subScribedMessages: string[];
  send: (message: Message) => void;
  static defaultProps: Partial<Props> = {
    isLoggedIn: false,
    role: "boater"
  };
  userProfileAPICallId: string = "";
  constructor(props: any) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      isDrawerOpen: false,
      anchorElUserProfileMenu: null,
      tabList: [
        { label: "Inbox", value: "HostChat" },
        { label: "Calendar", value: "Calendar" },
        { label: "Reservations", value: "ReservationList" },
        { label: "Manage Listing", value: "ManageListing" },
        { label: "Earnings", value: "Earnings" },
      ],
      boaterTabList: [
        { label: "Inbox", value: "BoaterChat" },
        { label: "Favorites", value: "Favourites" },
        { label: "My Reservations", value: "BoaterReservationList" },
      ],
      openModal: OpenModal.None,
      token: "",
      tempToken: "",
      email: "",
      password: "",
      continueFromLogin: Continue.No,
      userMenu: [
        {value: this.props.role === "host" ? "HostProfile" : "BoaterProfile", label: "Profile", icon: PersonOutlineIcon },
        {value: this.props.role === "host" ? "HostProfileSetting" : "BoaterProfileSetting", label: "Settings", icon: SettingsOutlinedIcon },
        {value: "LandingPage", label: "Logout", icon: ExitToAppOutlinedIcon }
      ],
      userProfileData:{id:0, first_name:"", last_name: '', profile_pic:''},
    };
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const token = await getStorageData("token"); 
    if(!!token) {
      this.setState({ token });
       this.getProfileDetails(token);
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) {
    if (!!this.state.token && (this.state.token !== prevState.token)) {
      const decodedTokenDetails: {
        id: number;
        exp: number;
        token_type: string;
      } = jwtDecode(this.state.token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedTokenDetails.exp < currentTime) {
        this.logout();
      }
    }
  }

  async receive(from: string, message: Message) {
    this.getUserProfileApi(message);
  }

  getUserProfileApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiProfileReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const userJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiProfileReqCallId === this.userProfileAPICallId) {
        this.setState({
          userProfileData: userJsonData.data.attributes
        })
      }
    }
  } 
  getProfileDetails = async(token: string) => {
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const getUserMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userProfileAPICallId = getUserMsg.messageId
      getUserMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        '/bx_block_settings/profile_settings/view')
      getUserMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getUserMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(getUserMsg.id, getUserMsg);
  }

  toggleDrawer = (flag: boolean) => {
    this.setState({ isDrawerOpen: flag });
  };

  handleTabChange = (route: string, stayLogin: boolean = false) => {
    if (route === "LandingPage" && !stayLogin) {
      this.logout();
    }
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    this.setState({ isDrawerOpen: false, anchorElUserProfileMenu: null });
  };

  handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (this.state.token) {
      this.setState({ anchorElUserProfileMenu: event.currentTarget });
    } else {
      this.setState({ openModal: OpenModal.Login });
    }
  };

  handleCloseUserMenu = () => {
    this.setState({ anchorElUserProfileMenu : null });
  };

  logout = async () => {
    await removeStorageData("token");
    await removeStorageData("propsData");
    await removeStorageData("loginId");
    await removeStorageData("selectedLake");
    this.setState({ token: "" });
    this.props.navigation.navigate("LandingPage");
    this.setState({ anchorElUserProfileMenu: null });
  };

  mustVerifyEmail = ({ email, password, tempToken }: MustVerifyEmail) => {
    this.setState({
      email, // Update tempToken, email, password; so they can continue with the signup flow
      password,
      tempToken,
      openModal: OpenModal.SignUp, // Set openModal = OpenModal.SignUp; continue to Continue.VerifyEmail
      continueFromLogin: Continue.VerifyEmail,
    });
  };

  mustCompleteProfile = ({
    tempToken,
    email,
    password,
  }: MustCompleteProfile) => {
    this.setState({
      openModal: OpenModal.SignUp,
      continueFromLogin: Continue.CompleteProfile,
      email, // Update tempToken, email, password; so they can continue with the signup flow
      password,
      tempToken,
    });
  };

  handleSwitchBtnClick = (role: string | undefined) => {
    if(role){
      const route = (role === "host") ? "Boater" : "JobListing";
      this.props.navigation.navigate(route);
    }
    else{
      this.props.navigation.navigate("JobListing");
    }
  } 

  render() {
    const {
      isDrawerOpen,
      tabList,
      boaterTabList,
      userMenu,
      anchorElUserProfileMenu,
      openModal,
      continueFromLogin,
      email,
      password,
      token,
      tempToken
    } = this.state;
    const isDrawerVisible = (this.props.role === "host") || (this.props.role === "boater");

    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <AppBar position="relative" elevation={0} className="headerWrapper">
              <Box className="toolBarWrapper" id="header-id">
                <img src={imgHeaderLogo} className="logoImg" onClick={() => this.handleTabChange("LandingPage", true)} />
                {token && (
                  <Tabs
                    className="tabVisible"
                    textColor="primary"
                    indicatorColor="primary"
                    value={getLastPartOfURL().url}
                    onChange={(event, value) => this.handleTabChange(value)}
                  >
                    {token &&
                      (this.props.role === "host"
                          ? tabList : boaterTabList
                      )?.map((tabItem) => {
                        return (
                          <Tab
                            key={tabItem.value}
                            value={tabItem.value}
                            label={tabItem.label}
                            className="textCapitalize"
                          />
                        );
                      })
                    }
                  </Tabs>
                )}
                <Box
                  className="buttonWrapper tabVisible"
                  style={{
                    display: isDrawerVisible ? "" : "flex",
                  }}
                >
                  <Button variant="contained" className="button" onClick={() => this.handleSwitchBtnClick(this.props.role)}>
                    Switch to{" "}
                    {this.props.role === "host" ? "boating" : "hosting"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="default"
                    id="basic-button"
                    className="button"
                    onClick={(e) => this.handleOpenUserMenu(e)}
                  >
                    {token ? (
                      <>
                        {this.state.userProfileData.profile_pic !== null ? 
                          <Avatar
                            style={{
                              marginRight: "5px",
                              width: "2rem",
                              height: "30px",
                              objectFit: "fill",
                            }}
                            alt={this.state.userProfileData.first_name}
                            src={this.state.userProfileData.profile_pic}
                          />
                          :
                          <img alt="user" src={userLogo} width= "30px" height= "30px" style={{ opacity: 0.6, marginRight: '12px' }} />                       
                        }
                        <>
                          <Tooltip 
                            title={this.state.userProfileData.first_name + " " + this.state.userProfileData.last_name}
                            arrow
                          >
                            <Typography
                              style={{
                                fontFamily: "Outfit",
                                fontSize: "1rem",
                                fontWeight: 700,
                                whiteSpace: "nowrap", 
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              color="primary"
                            >
                              AHOY, {this.state.userProfileData.first_name.charAt(0) + this.state.userProfileData.last_name.charAt(0)}!
                            </Typography>
                          </Tooltip>
                          <ArrowDropDownOutlined />
                        </>
                      </>
                    ) : (
                      <>Login/Sign up</>
                    )}
                  </Button>
                  <Menu
                    elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    id="menu-appbar"
                    className="userProfileMenu"
                    anchorEl={anchorElUserProfileMenu}
                    open={Boolean(anchorElUserProfileMenu)}
                    onClose={() => this.handleCloseUserMenu()}
                  >
                    {userMenu.map((menuItem) => {
                      return (
                        <MenuItem
                          key={menuItem.value}
                          onClick={() => this.handleTabChange(menuItem.value)}
                        >
                          <Box display="flex" alignItems="center" gridGap={10}>
                            <menuItem.icon />
                            <Typography align="center">
                              {menuItem.label}
                            </Typography>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
                <IconButton
                  className="menuIconVisible"
                  style={{
                    display: isDrawerVisible ? "" : "none",
                  }}
                >
                  <MenuIcon onClick={() => this.toggleDrawer(true)} />
                </IconButton>
              </Box>
            </AppBar>
            <Drawer
              anchor="top"
              open={isDrawerOpen}
              onClose={() => this.toggleDrawer(false)}
            >
              {token ? (
                <MenuItem
                  style={{
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {this.state.userProfileData.profile_pic !== null ?
                  <Avatar
                  style={{
                    width: "2rem",
                    height: "30px",
                    objectFit: "fill",
                  }}
                  alt={this.state.userProfileData.first_name}
                  src={this.state.userProfileData.profile_pic}
                />
                  :
                  <img alt="user" src={userLogo} width= "30px" height= "30px" style={{ opacity: 0.6, marginRight: '12px' }} /> 
                  }
                  
                  <Typography style={{ fontWeight: 600, color: "#202020" }}>
                    AHOY, {this.state.userProfileData.first_name}!
                  </Typography>
                </MenuItem>
              ) : null}
              <Tabs
                orientation="vertical"
                textColor="primary"
                indicatorColor="primary"
                value={getLastPartOfURL().url}
                onChange={(event, value) => this.handleTabChange(value)}
              >
                {isDrawerVisible &&
                  (this.props.role === "host"
                      ? tabList : boaterTabList
                  )?.map((tabItem) => {
                    return (
                      <Tab
                        key={tabItem.value}
                        value={tabItem.value}
                        label={tabItem.label}
                        className="textCapitalize"
                      />
                    );
                  })
                }
              </Tabs>
              <Divider />
              <Button variant="contained" className="button" onClick={() => this.handleSwitchBtnClick(this.props.role)}>
                Switch to{" "}
                {this.props.role === "host" ? "boating" : "hosting"}
              </Button>
              {userMenu.map((menuItem) => {
                return (
                  <MenuItem
                    key={menuItem.value}
                    onClick={() => this.handleTabChange(menuItem.value)}
                  >
                    <Box display="flex" alignItems="center" gridGap={10}>
                      <menuItem.icon />
                      <Typography align="center">{menuItem.label}</Typography>
                    </Box>
                  </MenuItem>
                );
              })}
              {!token ? (
                <Button
                  variant="outlined"
                  className="button"
                  onClick={() => this.handleTabChange("login")}
                >
                  Login/Sign up
                </Button>
              ) : null}
            </Drawer>
          </Wrapper>
        </ThemeProvider>
        <Login
          mustVerifyEmail={this.mustVerifyEmail}
          mustCompleteProfile={this.mustCompleteProfile}
          goToSignup={() => this.setState({ openModal: OpenModal.SignUp })}
          goToForgot={() => this.setState({ openModal: OpenModal.ForgotPassword })}
          isOpen={openModal === OpenModal.Login}
          onClose={async () => {
            const token = await getStorageData("token");
            this.setState({ token, openModal: OpenModal.None });
            token && this.getProfileDetails(token);
          }}
          navigation={this.props.navigation}
          id="login modal"
        />
        <Signup
          email={email}
          password={password}
          tempToken={tempToken}
          continueFromLogin={continueFromLogin}
          goToLogin={() => this.setState({ openModal: OpenModal.Login })}
          isOpen={openModal === OpenModal.SignUp}
          onClose={async () => {
            const token = await getStorageData("token");
            this.setState({ token, openModal: OpenModal.None });
          }}
          navigation={this.props.navigation}
          id="signup modal"
        />
        <ForgotModal
          goToLogin={() => this.setState({ openModal: OpenModal.Login })}
          isOpen={openModal === OpenModal.ForgotPassword}
          onClose={() => this.setState({ openModal: OpenModal.None })}
          navigation={this.props.navigation}
          id="forgot modal"
        />
      </>
    );
  }
}
