// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Maps from "../../blocks/maps/src/Maps";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Favourites from "../../blocks/favourites/src/Favourites.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotModal.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Reservations from "../../blocks/reservations/src/Reservations.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Chat from "../../blocks/chat/src/Chat.web";
import ViewChat from "../../blocks/chat/src/ViewChat";
import ReservationList from "../../blocks/reservations/src/ReservationList.web";
import BoaterReservationList from "../../blocks/reservations/src/BoaterReservationList.web";
import ManageListing from "../../blocks/reservations/src/ManageListing.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail.web";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Share from "../../blocks/share/src/Share.web";
import Earnings from "../../blocks/reservations/src/Earnings.web";
import Calendar from "../../blocks/reservations/src/Calendar.web";
import ManageListingDetails from "../../blocks/reservations/src/ManageListingDetails.web";
import JobListing from "../../blocks/joblisting/src/JobListing.web";
import ListingPreview from "../../blocks/joblisting/src/ListingPreview.web";
import ProfileSetting from "../../blocks/settings2/src/SettingsProfile.web";
import DockListingForm from "../../blocks/joblisting/src/DockListingForm.web";
import BoaterSearch from "../../blocks/landingpage/src/BoaterSearch.web";
import DockListingFeatures from "../../blocks/joblisting/src/DockListingFeatures.web";
import DockBooking from "../../blocks/reservations/src/DockBooking.web";

import DockListingRulesInstructions from "../../blocks/joblisting/src/DockListingRulesInstructions.web";
import SettingsPayment from "../../blocks/stripepayments/src/SettingsPayment.web";
import NotificationsSetting from "../../blocks/settings2/src/SettingsNotification.web";
import DockListingBasePrice from "../../blocks/joblisting/src/DockListingBasePrice.web";
import DockListingShowOff from "../../blocks/joblisting/src/DockListingShowOff.web";
import LoginSecuritySetting from "../../blocks/settings2/src/SettingsLoginSecurity.web";
import Profile from "../../blocks/settings2/src/Profile.web";
import FAQs from "../../blocks/joblisting/src/FAQs.web";
import TermsAndConditions from "../../blocks/joblisting/src/TermsAndConditions.web";
import PrivacyPolicy from "../../blocks/joblisting/src/PrivacyPolicy.web";
import ReviewPrompt from "../../blocks/reviewprompt/src/ReviewPrompt.web";

const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
LandingPage:{
 component:LandingPage,
 exact: true,
path:"/"},
Boater:{
  component:LandingPage,
  exact: true,
 path:"/boater"},
 BoaterSearch:{
  component:BoaterSearch,
  exact: true,
 path:"/boater/search/:navigationBarTitleText"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Location:{
 component:Location,
path:"/Location"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Maps:{
 component:Maps,
path:"/maps"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/mobile-account-login-block"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/boater/lake/:navigationBarTitleText/search"},
RequestManagement:{
 component:RequestManagement,
path:"/request-management"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Scheduling:{
 component:Scheduling,
path:"/scheduling"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/stripe-payments"},
Favourites:{
  component:Favourites,
 path:"/favourites"},
ForgotPassword:{
 component:ForgotPassword,
path:"/forgot-password"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/forgot-password-otp"},
NewPassword:{
 component:NewPassword,
path:"/new-password"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Reservations:{
 component:Reservations,
path:"/reservations"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
HostChat:{
 component:Chat,
path:"/host/chat",
exact: true},
ViewChat:{
 component:ViewChat,
path:"/view-chat"},
TermsConditions:{
 component:TermsConditions,
path:"/terms-conditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Share:{
  component:Share,
 path:"/Share"},
// Emailnotifications2:{
//  component:Emailnotifications2,
// path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
// Paymentadmin2:{
//  component:Paymentadmin2,
// path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Maps:{
 component:Maps,
path:"/Maps"},
BoaterChat:{
 component:Chat,
path:"/boater/chat",
exact: true},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
ReservationList:{
  component:ReservationList,
 path:"/host/reservations",
 exact: true,
},
PastReservationList:{
  component:ReservationList,
 path:"/host/reservations/past-reservations",
 exact: true,
},
BoaterReservationList:{
  component:BoaterReservationList,
path:"/boater/reservations/where-you-are-docking"},
BoaterDockedReservationList:{
  component:BoaterReservationList,
path:"/boater/reservations/where-you-have-docked"},
ManageListing:{
  component:ManageListing,
 path:"/manage-listing"},
Earnings:{
  component:Earnings,
 path:"/earnings"},
Calendar:{
  component:Calendar,
 path:"/calendar"},
ManageListingDetails:{
  component:ManageListingDetails,
 path:"/ManageListingDetails/:navigationBarTitleText"},
HostProfileSetting: {
  component: ProfileSetting,
  path: "/host/profile-setting",
},
BoaterProfileSetting: {
  component: ProfileSetting,
  path: "/boater/profile-setting",
},
HostProfile: {
  component: Profile,
  path: "/host/profile",
},
BoaterProfile: {
  component: Profile,
  path: "/boater/profile",
},
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  },

  JobListing:{
    component:JobListing,
   path:"/host",
   exact: true,
  },  
  FAQs:{
    component:FAQs,
   path:"/faqs",
   exact: true,
  },
  TermsAndConditions:{
    component:TermsAndConditions,
    path:"/terms-and-conditions",
    exact: true,
  },
  PrivacyPolicy:{
    component:PrivacyPolicy,
    path:"/privacy-policy",
    exact: true,
  },
  ListingPreview:{
    component:ListingPreview,
   path:"/listing-preview/:navigationBarTitleText"
  },

  DockListingForm:{
    component:DockListingForm,
   path:"/dock-listing-form"
  },
  DockListingFeatures:{
    component:DockListingFeatures,
   path:"/dock-listing-features/:navigationBarTitleText"
  },  
  DockListingBasePrice:{
    component:DockListingBasePrice,
   path:"/dock-listing-pricing/:navigationBarTitleText"
  },
  DockBooking: {
    component: DockBooking,
    path: "/dock-booking/:navigationBarTitleText"
  },
  DockListingRulesInstructions:{
    component:DockListingRulesInstructions,
   path:"/listing-rules-instructions/:navigationBarTitleText"
  },
  HostPaymentsSetting:{
    component:SettingsPayment,
    path:"/host/payments-setting"
  },
  BoaterPaymentsSetting:{
    component:SettingsPayment,
    path:"/boater/payments-setting"
  },
  HostNotificationsSetting: {
    component: NotificationsSetting,
    path: "/host/notifications-setting",
  },
  BoaterNotificationsSetting: {
    component: NotificationsSetting,
    path: "/boater/notifications-setting",
  },
  DockListingShowOff:{
    component:DockListingShowOff,
   path:"/dock-showcase/:navigationBarTitleText"
  },
  HostLoginAndSecuritySetting: {
    component: LoginSecuritySetting,
    path: "/host/login-and-security-setting",
  },
  BoaterLoginAndSecuritySetting: {
    component: LoginSecuritySetting,
    path: "/boater/login-and-security-setting",
  },
  Review: {
    component: ReviewPrompt,
    path: "/review",
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;