import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid, styled } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import NoDataFound from "../../../components/src/NoDataFound.web";
import { getLastPartOfURL } from "../../../components/src/utils";

const assets = require("./assets");

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans",
      },
      h4: {
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
      },
      h5: {
        fontSize: "1.3rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        paddingBottom: "8px",
      },
      h6: {
        fontSize: "1rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        color: "#4F9FF8",
      },
      body1: {
        fontSize: "1rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        color: "#0F172A",
      },
      body2: {
        fontSize: "0.8rem",
        fontFamily: "Outfit",
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: "1rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#64748B",
      },
      subtitle2: {
        fontSize: 14,
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#6B7280",
      },
      caption: {
        fontFamily: "Outfit",
        fontSize: "0.8rem",
        fontWeight: 400,
        color: "#94A3B8",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#4f9fff",
      },
      scrollButtons: {
        color: "#334155",
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: "#4f9fff",
        },
        width: "max-content !important",
        minWidth: "max-content !important",
        fontFamily: "Outfit",
        fontSize: "1rem",
        fontWeight: 700,
        color: "#334155",
      },
      textColorPrimary: {
        "&$selected": {
          color: "#4f9fff",
        },
        fontFamily: "Outfit",
        fontSize: "1rem",
        fontWeight: 700,
        color: "#334155",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Outfit, sans-serif",
        color: "#1275e4",
        borderColor: "#1275e4 !important",
        textTransform: "none",
        minWidth: "50px",
      },
      contained: {
        backgroundColor: "#1275e4",
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: "#FFFFFF",
          color: "#1275e4",
        },
      },
    },
    MuiList: {
      root: {
        padding: "0 !important",
      },
    },
    MuiListItem: {
      root: {
        gap: "15px",
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: "auto",
      },
    },
    MuiListItemText: {
      root: {
        margin: "0 !important",
      },
      primary: {
        color: "#000000",
        textAlign: "left",
        fontFamily: "Outfit",
        fontSize: 14,
        fontWeight: 400,
        gap: "15px",
        maxWidth: "max-content",
      },
    },
    MuiOutlinedInput: {
      inputAdornedEnd: {
        textOverflow: "ellipsis",
      },
      adornedEnd: {
        paddingRight: "0",
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "8px",
        minWidth: 80,
        color: "#000000",
        boxShadow: "#F1F5F9 0px 8px 32px 0px, #000000 0px 4px 5px 0px",
        "& li": {
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#FDDA96",
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        height: "auto",
      },
      list: {
        padding: "4px !important",
        width: "auto !important",
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Outfit",
        padding: "9px 20px 9px 16px !important",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0",
      },
    },
  },
});

const StyledRoomListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0 10px 10px 10px",
  [theme.breakpoints.down(840)]: {
    padding: "0",
  },
}));

const StyledDetailContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0 10px 10px 10px",
  [theme.breakpoints.down(840)]: {
    padding: "0",
  },
}));
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";
import { CircularProgress } from "@mui/material";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isChatOpen, openChatInfo, chatRoomList } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper data-test-id="mainWrapper" style={{height:"100vh", overflow:"hidden"}}>
          <Container maxWidth="lg">
            <Header
              role={getLastPartOfURL().role}
              navigation={this.props.navigation}
            />
            {(openChatInfo.id === -1 || isChatOpen) && (
              <Typography variant="h4" className="headerTitle">
                {configJSON.inboxTitleText}
              </Typography>
            )}
            {chatRoomList && chatRoomList.length > 0 && (
              <Grid container spacing={4} className="mainContainer">
                {(openChatInfo.id === -1 || isChatOpen) && (
                  <Grid item sm={5} xs={12} md={3} lg={3} className="gridItems">
                    <StyledRoomListContainer data-test-id="StyledRoomListContainer">
                      {this.renderRoomList()}
                    </StyledRoomListContainer>
                  </Grid>
                )}
                {(openChatInfo.id !== -1 || isChatOpen) && (
                  <>
                    <Grid
                      item
                      sm={7}
                      xs={12}
                      md={5}
                      lg={5}
                      style={{
                        padding: 0,
                      }}
                    >
                      <Box>{this.renderOpenChatModal()}</Box>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={4}
                      lg={4}
                    >
                      <StyledDetailContainer data-test-id="StyledDetailContainer">
                        {this.renderDetailModal()}
                      </StyledDetailContainer>
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            {
              chatRoomList && chatRoomList.length === 0 && (
                <NoDataFound
                  name={configJSON.notFound}
                  image={assets.notFoundIcon}
                  message={configJSON.noNewMessagesText}
                  redirectedMsg={configJSON.goToListings}
                  redirectionRoute="ManageListing"
                  navigation={this.props.navigation}
                />
              )
            }

            {
              chatRoomList === null && (
                <div data-test-id={'wrapper-loading'} style={{ height: "70vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress size={40} color={"error"} />
                </div>
              )
            }
          </Container>
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .delete-icon": {
    position: "absolute",
    top: 5,
    right: 5,
    opacity: 0,
    transition: "opacity 0.3s",
  },
  "& .attachment": {
    position: "relative",
    height: "5rem",
    width: "max-content",
    maxWidth: "4.5rem",
    borderRadius: "8px",
    "&:hover img": {
      opacity: 0.5,
      background:
        "linear-gradient(180deg, rgba(38, 36, 36, 0.47) 0%, rgba(38, 36, 36, 0) 100%)",
    },
    "&:hover .delete-icon": {
      opacity: 1,
    },
  },
  "& .paddingTop": {
    paddingTop: "15px",
  },
  "& .marginTop10": {
    marginTop: "10px",
  },
  "& .coloredSubtitle": {
    color: "#0F172A",
  },
  "& .fontWeight600": {
    fontWeight: 600,
  },
  "& .pointer": {
    cursor: "pointer",
  },
  "& .textOverflow": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "10rem",
  },
  "& .mainContainer": {    
    margin: "0 0 30px",
    width: "100%",
  },
  "& .gridItems": {
    overflow: "hidden",
    overflowY: "scroll" as "scroll",
    height: "696px",
    padding: 0,
  },
  "& .listItemContainer": {
    width: "100%",
    minHeight: "72px",
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
    padding: "8px 4px 8px 4px",
  },
  "& .mainChatContainer": {
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "column",
  },
  "& .openChatContainer": {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 4px 8px 4px",
  },
  "& .detailContainer": {
    padding: "8px 4px 8px 4px",
  },
  "& .detailIcon": {
    width: "100%",
    height: "auto",
    borderRadius: "18px",
    objectFit: "cover" as "cover",
  },
  "& .headerTitle": {
    padding: "10px 0 10px",
  },
  "& .messageDate": {
    display: "flex",
    gap: "20px",
    margin: "0 2.5rem",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .messageInput": {
    width: "100%",
    borderColor: "#CBD5E1",
    fontFamily: "Outfit",
    margin: 0,
  },
  "& .imageIcon": {
    color: "#B7B5B5",
    cursor: "pointer",
  },
  "& .filterButton": {
    backgroundColor: "transparent",
  },
  "& .chatIcon": {
    width: "22%",
    minWidth: "56px",
    height: "auto",
    borderRadius: "8px",
  },
  "& .attachmentImgs": {
    marginTop: "5px",
    width: "100%",
    height: "auto",
    borderRadius: "8px",
    objectFit: "fill",
  },
  "& .chatDetailIcon": {
    width: "16%",
    minWidth: "56px",
    minHeight: "80px",
    height: "auto",
    borderRadius: "8px",
    objectFit: "contain",
  },
  "& .chatContent": {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  "& .backBtnVisible": {
    display: "none",
  },
  "& .detailContent": {
    paddingTop: "15px",
  },
  "& .checkInOutContainer": {
    paddingTop: "15px",
    display: "flex",
    height: "auto",
  },
  "& .checkInBox": {
    width: "50%",
    padding: "8px",
    display: "flex",
    flexFlow: "column",
    border: "1px solid #D1D5DB",
    borderWidth: "1px 0 1px 1px",
    borderRadius: "8px 0 0 0",
  },
  "& .checkOutBox": {
    width: "50%",
    padding: "8px",
    display: "flex",
    flexFlow: "column",
    border: "1px solid #D1D5DB",
    borderRadius: "0 8px 0 0",
  },
  "& .selectGuestStyle": {
    minWidth: "100%",
    boxSizing: "border-box",
    padding: "8px",
    border: "1px solid #D1D5DB",
    borderWidth: "0 1px 1px 1px",
    borderRadius: "0 0 8px 8px",
  },
  "& .react-datepicker__input-container": {
    display: "none",
  },
  "& .react-datepicker": {
    padding: "0 15px",
  },
  "& .react-datepicker-popper": {
    padding: 0,
  },
  "& .react-datepicker__header": {
    backgroundColor: "transparent",
    border: "none",
  },
  "& .react-datepicker__navigation--previous": {
    margin: "15px 25px",
  },
  "& .react-datepicker__navigation--next": {
    margin: "15px 25px",
  },
  "& .react-datepicker__current-month, .react-datepicker-time__header": {
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#0F172A",
    padding: "10px",
  },
  "& .react-datepicker__day-names": {
    fontFamily: "Outfit",
  },
  "& .react-datepicker__day-name": {
    padding: "0 5px",
    color: "#64748B",
  },
  "& .react-datepicker__day": {
    fontFamily: "Outfit",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#4F9FF873",
      color: "#fff",
      borderRadius: "50%",
    },
  },
  "& .react-datepicker__time-list-item": {
    fontFamily: "Outfit",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#4F9FF873 !important",
      color: "#fff",
    },
  },
  "& .react-datepicker__day--selected": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__time-list-item--selected": {
    backgroundColor: "#4F9FF8 !important",
    color: "#fff",
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--in-range": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--in-selecting-range": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--outside-month": {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down(1200)]: {
    "& .textOverflow": {
      width: "8rem",
    },
  },
  [theme.breakpoints.down(1060)]: {
    "& .textOverflow": {
      width: "6rem",
    },
  },
  [theme.breakpoints.down(960)]: {
    "& .gridItems": {
      height: "auto",
    },
    "& .textOverflow": {
      width: "12rem",
    },
  },
  [theme.breakpoints.down(840)]: {
    "& .headerTitle": {
      fontSize: "1.3rem",
    },
    "& .secondaryTitle": {
      fontSize: "1.2rem",
    },
    "& .mainContainer": {
      padding: "0",
    },
    "& .chatIcon": {
      width: "15%",
    },
    "& .detailContainer": {
      padding: "30px 0",
    },
    "& .listItemContainer": {
      minHeight: "auto",
      width: "auto",
    },
    "& .textOverflow": {
      width: "10rem",
    },
  },
  [theme.breakpoints.down(710)]: {
    "& .textOverflow": {
      width: "9rem",
    },
  },
  [theme.breakpoints.down(650)]: {
    "& .react-datepicker": {
      display: "grid",
    },
    "& .react-datepicker__navigation--previous": {
      margin: "10px",
    },
    "& .react-datepicker__navigation--next": {
      margin: "10px",
    },
    "& .textOverflow": {
      width: "7rem",
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .listItemContainer": {
      minHeight: "auto",
      width: "100%",
    },
    "& .backBtnVisible": {
      display: "block",
    },
    "& .textOverflow": {
      width: "25rem",
    },
  },
  [theme.breakpoints.down(540)]: {
    "& .textOverflow": {
      width: "20rem",
    },
  },
  [theme.breakpoints.down(460)]: {
    "& .textOverflow": {
      width: "15rem",
    },
  },
  [theme.breakpoints.down(380)]: {
    "& .textOverflow": {
      width: "10rem",
    },
  },
}));
// Customizable Area End
