import React from "react";
// Customizable Area Start
import { Container, Typography, Grid, Box, styled } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CircularProgress, StyledEngineProvider } from "@mui/material";
import Header from "../../../components/src/Header";
import CustomInput from "../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import CustomCheckboxSelect from "../../../components/src/CustomCheckboxSelect.web";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import TuneIcon from "@material-ui/icons/Tune";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import { ValueType } from "react-select";
import CustomMap from "../../../components/src/CustomMap";
import NoDataFound from "../../../components/src/NoDataFound.web";
import Toast from "../../../components/src/Toast";
import Login from "../../../blocks/email-account-login/src/Login.web";
import CloseIcon from "@material-ui/icons/Close";

const assets = require("./assets");
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#1275e4",
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: "2rem !important",
        fontFamily: "Josefin Sans !important",
      },
      h5: {
        fontSize: "1.5rem !important",
        fontFamily: "Josefin Sans !important",
      },
      body1: {
        fontSize: "1rem !important",
        fontFamily: "Outfit !important",
        fontWeight: 400,
        color: "#0F172A !important",
      },
    },
    MuiContainer: {
      maxWidthMd: {
        maxWidth: "1200px !important",
      },
    },
  },
});

interface IOption {
  value: string;
  label: string;
}
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            {/* Customizable Area Start */}
            <Wrapper>
              <Header navigation={this.props.navigation} />
              {this.state.errorMsg && (
                <Toast data-test-id="errorMsg" message={this.state.errorMsg} />
              )}
              <Typography variant="h1" className="headerTitle fontWeight700">
                {this.state.dockTitle}
              </Typography>
              <Grid container spacing={2} className="searchBar">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <CustomInput
                    data-test-id="SearchByText"
                    size="small"
                    placeholder="Search nearby"
                    value={this.state.searchedText}
                    onChange={(event) => this.handleSearchByText(event)}
                    startIcon={SearchRoundedIcon}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <CustomDatePicker
                    data-test-id="SearchByDates"
                    startIcon={CalendarTodayRoundedIcon}
                    value={
                      this.state.selectedStartDate && this.state.selectedEndDate
                        ? `${this.state.selectedStartDate.toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "long",
                            }
                          )} - ${this.state.selectedEndDate.toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "long",
                            }
                          )}`
                        : ""
                    }
                    onChange={(dates) =>
                      this.handleAvailabilityByDates(
                        dates as [Date | null, Date | null]
                      )
                    }
                    placeholder="Availability"
                    monthsShown={2}
                    startDate={this.state.selectedStartDate}
                    endDate={this.state.selectedEndDate}
                    InputProps={{
                      endAdornment: (
                        <CloseIcon
                          style={{
                            width: "1rem",
                            color: "grey",
                            cursor: "pointer",
                            display: this.state.selectedStartDate && this.state.selectedEndDate ? "" : "none",
                          }}
                          onClick={() => {
                            this.setState({
                              selectedStartDate: null,
                              selectedEndDate: null,
                            });
                          }}
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <CustomCheckboxSelect
                    data-test-id="SearchByFeatures"
                    options={this.state.featureList}
                    value={this.state.selectedFeatures}
                    placeholder="Features"
                    onChange={(value: ValueType<IOption, true>) =>
                      this.handleFeatureChanges(value)
                    }
                    startIcon={TuneIcon}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={12}>
                  <CustomCheckboxSelect
                    optionType="radio"
                    data-test-id="SearchByPrices"
                    options={this.state.priceRangeList}
                    value={
                      this.state.selectedPriceRange?.label
                        ? [this.state.selectedPriceRange]
                        : []
                    }
                    placeholder="Price"
                    onChange={(value) => this.handlePriceRangeChanges(value)}
                    startIcon={MonetizationOnOutlinedIcon}
                  />
                </Grid>
              </Grid>
              <Box className="scrollbar">
                {this.state.availableDockList &&
                this.state.availableDockList.length ? (
                  <Box className="mapContainer">
                    <CustomMap
                      destinationAddress={this.state.searchedText}
                      scrollwheel={false}
                      lakes={this.state.availableDockList} 
                      navigation={this.props.navigation}                     
                    />
                  </Box>
                ) : <div data-test-id={'wrapper-loading'} style={{ height: "70vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <CircularProgress size={40} color={"error"} />
                                </div>}
                <Typography variant="h5" className="fontWeight700 margin20">
                  Docks in the area
                </Typography>
                {this.state.availableDockList && this.state.availableDockList.length ? (
                  <Box>
                    <Box style={{ display: "flex" }}>
                      <Grid container className="dockListContainer" spacing={2}>
                        {this.renderCards()}
                      </Grid>
                    </Box>
                    {this.renderPaginationAndSummary()}
                  </Box>
                ) : (
                  <>
                  {
                    !this.state.isLoading && this.state.availableDockList && this.state.availableDockList.length === 0 ? (
                      <Box data-test-id="titleTextError" >

                        <NoDataFound
                          image={assets.notFoundImg}
                          message="No Docks Found!"
                          name="No docks found"
                        />
                      </Box>
                    ) : (
                      <Box style={{height: '1150px'}}></Box>
                    )
                  }
                  </>
                )}
              </Box>
            </Wrapper>
            <Login
              data-test-id="login-modal"
              isOpen={this.state.loginModal}
              onClose={() => {
                this.setState({ loginModal: false });
              }}
              navigation={this.props.navigation}
              id="login modal"
            />
            {/* Customizable Area End */}
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")({
  "& .fontWeight700": {
    fontWeight: "700 !important",
  },
  "& .headerTitle": {
    margin: "15px 0 20px 0",
  },
  "& .searchBar": {
    display: "flex",
    alignItems: "center",
  },
  "& .mapContainer": {
    height: "476px",
  },
  "& .dockListContainer": {
    margin: "15px 0 0 0",
  },
  "& .margin20": {
    margin: "20px 0 0 0",
  },
  "& .dockListCard": {
    boxShadow: "none",
  },
  "& .dockListImage": {
    height: "172px",
    borderRadius: "15px",
  },
  "& .favIcon": {
    padding: "8px",
    position: "relative",
    top: "12px",
    backgroundColor: "#ffffffdb",
    color: "#141414",
    left: "78%",
  },
  "& .cardContent": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .cursorPointer": {
    cursor: "pointer",
    "&:hover": {
      fontWeight: 700,
    },
  },
  "& .ratingText": {
    display: "flex",
    gap: 5,
  },
  "& .ratingIcon": {
    color: "#DE3151",
  },
  "& .pagination": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 50px 0 0",
    gap: 40,
    "& button": {
      textDecoration: "underline",
    },
  },
  "& .Mui-selected": {
    fontWeight: 700,
    backgroundColor: "transparent",
    textDecoration: "none !important",
  },
  "& .scrollbar": {
    height: "100vh",
    overflow: "auto",
    margin: "20px -17% 50px 0",
    padding: "0px 17% 0px 0px",
  },
  [theme.breakpoints.down(1545)]: {
    "& .scrollbar": {
      margin: "20px -16% 50px 0",
      padding: "0px 16% 0px 0px",
    },
  },
  [theme.breakpoints.down(1495)]: {
    "& .scrollbar": {
      margin: "20px -13% 50px 0",
      padding: "0px 13% 0px 0px",
    },
  },
  [theme.breakpoints.down(1450)]: {
    "& .scrollbar": {
      margin: "20px -11% 50px 0",
      padding: "0px 11% 0px 0px",
    },
  },
  [theme.breakpoints.down(1400)]: {
    "& .scrollbar": {
      margin: "20px -8% 50px 0",
      padding: "0px 8% 0px 0px",
    },
  },
  [theme.breakpoints.down(1330)]: {
    "& .scrollbar": {
      margin: "20px -5% 50px 0",
      padding: "0px 5% 0px 0px",
    },
  },
  [theme.breakpoints.down(1250)]: {
    "& .scrollbar": {
      margin: "20px -2% 50px 0",
      padding: "0px 2% 0px 0px",
    },
  },
  [theme.breakpoints.down(510)]: {
    "& .dockListCard": {
      width: "100%",
    },
    "& .favIcon": {
      left: "89%",
    },
  },
  [theme.breakpoints.down(500)]: {
    "& .pagination": {
      padding: "30px 20px 0 20px",
      gap: 5,
    },
    "& .favIcon": {
      left: "88%",
    },
  },
  [theme.breakpoints.down(430)]: {
    "& .favIcon": {
      left: "87%",
    },
  },
  [theme.breakpoints.down(370)]: {
    "& .favIcon": {
      left: "84%",
    },
  },
});
// Customizable Area End
