import React from "react";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { mapPin } from "./assets";
import { IGeocodeAddress } from "../../blocks/joblisting/src/DockListingFormController.web";
import { Box, Typography } from "@material-ui/core";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import { IDockListItem } from "../../blocks/advancedsearch/src/AdvancedSearchController";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { v4 as uuidv4 } from "uuid";

const API_KEY = "AIzaSyAVO6Wjalq9KT30ZOt8j_mQ5A6-tuFxx0Q";
const assets = require("../../blocks/advancedsearch/src/assets");

interface ICoordinates {
  lat: number;
  lng: number;
}

interface ICustomMapProps { 
  navigation: any 
  destinationAddress: string;
  onAddressChange?: (geocodeAddress: IGeocodeAddress) => void;
  scrollwheel?: boolean;
  lakes?: IDockListItem[];
  defaultMarkerPosition?: ICoordinates;
}

interface IMapMarkerState {
  markerPosition: ICoordinates;
  currentLocation: ICoordinates;
  selectedLake: IDockListItem;
}

class Map extends React.Component<ICustomMapProps, IMapMarkerState> {
  subScribedMessages: string[];
  send: (message: Message) => void;
  constructor(props: any) {
    super(props);
    this.state = {
      markerPosition: { lat: 40.712776, lng: -74.005974 },
      currentLocation: { lat: 40.712776, lng: -74.005974 },
      selectedLake: {} as IDockListItem,
    };
    this.subScribedMessages = [      
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),                  
    ];
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount() {
    if (this.props.defaultMarkerPosition)
      this.setState({ markerPosition: this.props.defaultMarkerPosition });
    if (this.props.lakes) this.setState({ selectedLake: this.props.lakes[0] });
    this.updateLocation();
  }

  async receive(from: string, message: Message) {
      
  }
  componentDidUpdate(prevProps: ICustomMapProps) {
    if (prevProps.destinationAddress !== this.props.destinationAddress) {
      this.updateLocation();
    }
    if (
      this.props.defaultMarkerPosition &&
      prevProps.defaultMarkerPosition !== this.props.defaultMarkerPosition
    ) {
      this.setState({ markerPosition: this.props.defaultMarkerPosition });
    }
    if (
      this.props.lakes?.length === 0 &&
      prevProps.lakes !== this.props.lakes
    ) {
      this.setState({
        selectedLake: {} as IDockListItem,
        markerPosition: {} as ICoordinates,
      });
    }
    if (this.props.lakes?.length && prevProps.lakes !== this.props.lakes) {
      this.setState({ selectedLake: this.props.lakes[0] });
    }
  }

  goToDockBooking = (dockId: number) => {    
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'DockBooking'
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (dockId) {
      msg.addData(getName(MessageEnum.NavigationScreenNameMessage), dockId);
    }
    
    this.send(msg);    
  };
  updateLocation = () => {
    const { destinationAddress } = this.props;

    const geocoder = new (window as any).google.maps.Geocoder();
    geocoder.geocode(
      { address: destinationAddress },
      (
        results: google.maps.GeocoderResult[] | null,
        status: google.maps.GeocoderStatus
      ) => {
        if (status === "OK" && results && results.length > 0) {
          const location = results[0].geometry.location;
          this.setState({
            currentLocation: { lat: location.lat(), lng: location.lng() },
          });
        }
      }
    );
  };

  handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng && !this.props.lakes) {
      const lat = e.latLng?.lat();
      const lng = e.latLng?.lng();
      this.setState({ 
        markerPosition: { lat, lng }, 
        currentLocation: { lat, lng }
      });

      // Use Geocoding API to convert lat/lng to address
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { location: { lat, lng } },
        (
          results: google.maps.GeocoderResult[] | null,
          status: google.maps.GeocoderStatus
        ) => {
          if (status === "OK" && results !== null && results[0]) {
            const result = results[0];
            const geocodeAddress = {
              latitude: lat.toString(),
              longitude: lng.toString(),
              formatted_address: result.formatted_address,
              locality: this.getComponent(result, "locality").long_name,
              administrative_area_level_2: this.getComponent(
                result,
                "administrative_area_level_2"
              ).long_name,
              administrative_area_level_1: this.getComponent(
                result,
                "administrative_area_level_1"
              ).long_name,
              state_short_name: this.getComponent(result, "administrative_area_level_1")
                .short_name,
              postal_code: this.getComponent(result, "postal_code").long_name
                ? this.getComponent(result, "postal_code").long_name
                : result.plus_code?.global_code || "",
            };
            if (this.props.onAddressChange)
              this.props.onAddressChange(geocodeAddress);
          }
        }
      );
    }
  };

  handleMarkerClick = (lake: IDockListItem) => {
    this.setState({
      selectedLake: lake,
    });
  };
  // Function to get long_name and short_name for specific type
  getComponent = (result: google.maps.GeocoderResult, type: string) => {
    const addressComponents = result.address_components;
    const component = addressComponents.find((component) =>
      component.types.includes(type)
    );
    if (component) {
      return {
        long_name: component.long_name,
        short_name: component.short_name,
      };
    } else {
      return {
        long_name: "",
        short_name: "",
      };
    }
  };

  render() {
    const { lakes, scrollwheel } = this.props;
    const { selectedLake, currentLocation, markerPosition } = this.state;
    return (
      <>
        <GoogleMap
          defaultZoom={lakes ? 4 : 8}
          center={
            selectedLake.id
              ? new google.maps.LatLng(
                  Number(selectedLake.latitude),
                  Number(selectedLake.longitude)
                )
              : new google.maps.LatLng(currentLocation.lat, currentLocation.lng)
          }
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            scrollwheel: scrollwheel,
            gestureHandling: "greedy", // Disables "Use Ctrl + scroll to zoom" message
          }}
          onClick={(event) => this.handleMapClick(event)}
        >
          {lakes &&
            lakes.map((lake: IDockListItem) => {
              if (
                selectedLake.latitude !== lake.latitude &&
                selectedLake.longitude !== lake.longitude
              )
                return (
                  <Marker
                    key={lake.id}
                    position={{
                      lat: Number(lake.latitude),
                      lng: Number(lake.longitude),
                    }}
                    label={{
                      text: `$${lake.guest_total || 0}`,
                      color: "#374151",
                      fontSize: "16px",
                      className: "price-marker-label",
                    }}
                    icon={{
                      url: "",
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                    onClick={() => this.handleMarkerClick(lake)}
                  />
                );
            })}
          {selectedLake.latitude && selectedLake.longitude ? (
            <Marker
              position={{
                lat: Number(selectedLake.latitude),
                lng: Number(selectedLake.longitude),
              }}
              icon={{
                url: mapPin,
                scaledSize: new window.google.maps.Size(53, 53),
              }}
            >
              {selectedLake.id && (
                <InfoWindow
                  position={{
                    lat: Number(selectedLake.latitude),
                    lng: Number(selectedLake.longitude),
                  }}                  
                  onCloseClick={() =>
                    this.setState({
                      selectedLake: {} as IDockListItem,
                    })
                  }
                >
                  <Box
                    style={{
                      padding: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 15,
                      justifyContent: "space-between",
                    }}
                    onClick={() => this.goToDockBooking(Number(selectedLake.id))}
                  >
                    <Box style={{ display: "flex", gap: "10px" }}>
                      <img
                        src={selectedLake.images?.[0] || assets.cardMediaImage}
                        alt={
                          selectedLake.lake?.name || selectedLake.listing_title
                        }
                        style={{
                          width: "53px",
                          height: "53px",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                        loading="lazy"
                      />
                      <Box>
                        <Typography
                          style={{
                            color: "#0F172A",
                            fontFamily: "Outfit",
                            fontSize: "1.1rem",
                            fontWeight: 700,
                          }}
                        >
                          {selectedLake.lake || selectedLake.listing_title}
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            gap: 3,
                            alignItems: "center",
                            color: "#0F172A",
                            fontFamily: "Outfit",
                            fontSize: "0.9rem",
                            fontWeight: 400,
                          }}
                        >
                          <StarBorderRoundedIcon style={{ color: "#DE3151" }} />
                          {(selectedLake.host?.rating || 0).toFixed(1)}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      style={{
                        color: "#0F172A",
                        fontFamily: "Josefin Sans",
                        fontSize: "1.5rem",
                        fontWeight: 700,
                      }}
                    >
                      ${selectedLake.guest_total || 0}
                    </Typography>
                  </Box>
                </InfoWindow>
              )}
            </Marker>
          ) : (
            <Marker
              position={markerPosition}
              icon={{
                url: mapPin,
                scaledSize: new window.google.maps.Size(53, 53),
              }}
            />
          )}
        </GoogleMap>
        <style>{`
              .gm-style-cc,
              .gm-style-cc,
              .gm-style .gm-style-cc {
                display: none;
              }
              .gm-style-iw-chr {
                display: none;
              }
              .gm-style .gm-style-iw-c {
                padding: 0;
              }
              .price-marker-label {
                background-color: white;
                border-radius: 18px;
                padding: 8px 10px;
              }
            `}</style>
      </>
    );
  }
}
const MapWithAMarker = withScriptjs(withGoogleMap(Map));

export default class CustomMap extends React.Component<ICustomMapProps, IMapMarkerState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {
      destinationAddress,
      onAddressChange,
      scrollwheel = true,
      lakes,
      defaultMarkerPosition,
      navigation
    } = this.props;
    return (
      <MapWithAMarker      
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={
          <div style={{ height: `100%`, borderRadius: "18px" }} />
        }
        containerElement={
          <div style={{ height: `100%`, borderRadius: "18px" }} />
        }
        mapElement={<div style={{ height: `100%`, borderRadius: "18px" }} />}
        onAddressChange={onAddressChange}
        destinationAddress={destinationAddress}
        scrollwheel={scrollwheel}
        lakes={lakes}
        navigation={navigation}
        defaultMarkerPosition={defaultMarkerPosition}
      />
    );
  }
}
