// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ReservationTable from "./ReservationTable.web";
import NoDataFound from "../../../components/src/NoDataFound.web";
import { Typography } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { CircularProgress } from "@mui/material";

export interface ReservationItem {
  id: number;
  guest: string;
  check_in: string;
  check_out: string;
  listing: string;
  total: string;
  status: string;
  [key: string]: string | number;
}

interface Listdata {
  id: string;
  type: string;
  attributes: {
    id: number;
    author_info: { id: number; name: string };
    check_in: string;
    check_out: string;
    dock_address: string;
    total_payment: string;
    booking_status: string;
    dock_listing_id: number
  }
}
export const configJSON = require("./config");
export const assets = require("./assets");

export interface Props {
  navigation: any;
  propId: string;
}

export interface IHeader {
  id: string;
  label: string;
}

interface SProps {
  selectedView: string;
  reservationHeader: IHeader[];
  pendingReservationList: ReservationItem[] | null;
  pastReservationList: ReservationItem[] | null;
  isLoading: boolean;
  total_count:number;
  total_pages:number;
}

interface SSProps {
  ssId: string;
}

export default class ReservationListController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  getReservationListApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      selectedView: configJSON.dockRequestsText,
      total_count:0,
      total_pages:0,
      reservationHeader: [
        { id: "guest", label: "Guests" },
        { id: "check_in", label: "Check-in" },
        { id: "check_out", label: "Check-out" },
        { id: "listing", label: "Listing" },
        { id: "total", label: "Total Payment" },
      ],
      pendingReservationList:null,
      pastReservationList:null,
      isLoading: true
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    let urlPath = window.location.pathname;
    let parts = urlPath.split("/");
    parts.includes("past-reservations") ? this.setState({selectedView: "Past reservations"}): this.setState({selectedView: "Dock requests"})
    this.fetchReservationList(1);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.getReservationApi(message)
  } 
  getReservationApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const addJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiRequestCallId === this.getReservationListApiCallId) {
        const newPastReservationList = addJsonData.data.map((list: Listdata) => {
          const attrs = list.attributes;
          return {
            id: attrs.id,
            author_id: attrs.author_info.id,
            guest: attrs.author_info.name,
            check_in: attrs.check_in,
            check_out: attrs.check_out,
            listing: attrs.dock_address,
            total: attrs.total_payment,
            status: attrs.booking_status,
            dockId: attrs.dock_listing_id
          };
        })
        this.setState({
          pendingReservationList: newPastReservationList,
          pastReservationList: newPastReservationList,
          total_count: addJsonData.meta.pagination.total_count,
          total_pages:addJsonData.meta.pagination.total_pages,
          isLoading: false         
        });
      }
    }
    }
  fetchReservationList = async(page:number) => {
    const token = await getStorageData("token");
    const lastPath = this.state.selectedView === 'Dock requests' ? 'active' : '';
    const itemHeaders = {
      "Content-Type": configJSON.itemListApiContentType,
      'token': token
    };

    const getReservationListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReservationListApiCallId = getReservationListMsg.messageId;

    getReservationListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.urlGetItemList}/requests?status=${lastPath}&page=${page}&per=6`
    );

    getReservationListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(itemHeaders)
    );
    getReservationListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.itemListApiMethodType
    );

    runEngine.sendMessage(getReservationListMsg.id, getReservationListMsg);
    return true;
  };
  handleTabChange = (route: string) => {
    const msgData = new Message(getName(MessageEnum.NavigationMessage));
    msgData.addData(getName(MessageEnum.NavigationTargetMessage), route);
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgData);
  };
  handleListView = (chipLabel: string) => {
    let reservationType = chipLabel.includes('Past') ? "PastReservationList" : "ReservationList";
    this.handleTabChange(reservationType)
    this.setState({ selectedView: chipLabel });
  };
 
  renderReservationTable = () => {
    if (
      this.state.selectedView === configJSON.dockRequestsText &&
      this.state.pendingReservationList && this.state.pendingReservationList.length
    ) {
      return (
        <>
          <Typography
            variant="subtitle1"
            className="coloredSubtitle fontWeight600 padding30"
          >
            {configJSON.dockRequestsText}
          </Typography>
          <ReservationTable
            testId={this.state.selectedView}
            headerList={this.state.reservationHeader}
            listData={this.state.pendingReservationList}
            actions={["Accept", "Reject"]}
            totalCount={this.state.total_count}
            totalPages={this.state.total_pages}
            pageChange={(page:number)=>this.fetchReservationList(page)}
            navigation={this.props.navigation}
          />
        </>
      );
    } else if (
      this.state.selectedView === configJSON.dockedText &&
      this.state.pastReservationList && this.state.pastReservationList.length
    ) {
      return (
        <>
          <Typography
            variant="subtitle1"
            className="coloredSubtitle fontWeight600 padding30"
          >
            {configJSON.dockedText}
          </Typography>
          <ReservationTable
            testId={this.state.selectedView}
            headerList={this.state.reservationHeader}
            listData={this.state.pastReservationList}
            totalCount={this.state.total_count}
            totalPages={this.state.total_pages}
            pageChange={(page:number)=>this.fetchReservationList(page)}
          />
        </>
      );
    } else {
      if(!this.state.isLoading && this.state.pendingReservationList !== null && this.state.pastReservationList !== null){        
        return (
          <>
           <NoDataFound
             name="No reservations"
             image={assets.noReservationIcon}
             message={
               this.state.selectedView === configJSON.dockRequestsText
                 ? configJSON.noUpcomingReservationsText
                 : configJSON.noReservationsText
             }
             redirectedMsg={configJSON.goToListings}
             redirectionRoute={"ManageListing"}
             navigation={this.props.navigation}
           />
          </>
        );
      } else {
        return <div data-test-id={'wrapper-loading'} style={{ height: "70vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CircularProgress size={40} color={"error"} />
                        </div>
      }
    }
  };
}

// Customizable Area End
