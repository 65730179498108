// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";

export interface EarningItem {
  id: number;
  guest: string;
  date: string;
  amount: string;
  transaction_id: string;
  transactions: number;
  [key: string]: string | number;
}

export const configJSON = require("./config");
export const assets = require("./assets");

export interface Props {
  navigation: any;
  propId: string;
}

export interface IHeader {
  id: string;
  label: string;
}

interface IPaginate {
  current_page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
}

interface SProps {
  monthlyEarning: number;
  earningsHeader: IHeader[];
  earningsList: EarningItem[] | null;
  paginate: IPaginate;
  errorMsg: string;
}

interface SSProps {
  ssId: string;
}

export default class EarningsController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  getEarningsListAPICallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      monthlyEarning: 0,
      earningsHeader: [
        { id: "guest", label: "Name" },
        { id: "date", label: "Date" },
        { id: "amount", label: "Amount" },
        { id: "transaction_id", label: "Transaction ID" },
        { id: "transactions", label: "Transactions" },
      ],
      earningsList: null,
      paginate: {
        current_page: 1,
        per_page: 6,
        total_count: 0,
        total_pages: 1,
      },
      errorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJson);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchEarnings();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getEarningsListAPICallId]: this.handleEarningsDataAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  fetchEarnings = async () => {
    this.setState({ errorMsg: "" });
    const token = await getStorageData("token");
    this.getEarningsListAPICallId = sendAPIRequest(
      `${configJSON.urlGetEarningsList}?page=${this.state.paginate.current_page}&per=${this.state.paginate.per_page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handleEarningsDataAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: {
        id: string;
        type: string;
        attributes: {
          id: number;
          account: string;
          date_and_transactions: {
            date: string;
            transactions: number;
            transaction_id: string;
          };
          price: string;
        };
      }[];
      monthly_earnings?: number;
      meta?: { pagination: IPaginate };
      errors?: string[];
    };
    if (
      response.data &&
      response.monthly_earnings &&
      response.monthly_earnings !== null &&
      response.meta
    ) {
      this.setState({
        earningsList: response.data?.map((listItem) => {
          return {
            id: listItem.attributes.id,
            guest: listItem.attributes.account,
            date: listItem.attributes.date_and_transactions.date,
            amount: `$${listItem.attributes.price}`,
            transaction_id:
              listItem.attributes.date_and_transactions.transaction_id,
            transactions:
              listItem.attributes.date_and_transactions.transactions,
          };
        }),
        monthlyEarning: response.monthly_earnings,
        paginate: response.meta.pagination,
      });
    } else if (response.errors) {
      this.setState({
        errorMsg: response.errors[0],
      });
    }
  };

  handleChangePage = (value: number) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        paginate: {
          ...prevState.paginate,
          current_page: value,
        },
      };
    }, this.fetchEarnings);
  };
}

// Customizable Area End
