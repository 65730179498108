// Customizable Area Start
import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  setStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import { checkIfEighteenOrOlder, sendAPIRequest, validateEmailFormat, getDaysInMonth, Continue } from "../../../components/src/utils";

export const configJSON = require("./config");

type ErrorType = string | { account: string }[];
type LoginResponseType = { meta: { token: string; } };

export enum Step {
  EnterEmail = 1,
  EnterPassword,
  VerifyEmail,
  EnterRequiredFields,
  EnterOptionalFields
}

export interface Props {
  navigation: any;
  id: string;
  isOpen: boolean;
  onClose: () => void;
  goToLogin: () => void;
  continueFromLogin: Continue;
  email: string;
  password: string;
  tempToken: string;
}

interface S {
  isLoading: boolean;
  currentStep: Step;
  token: string;
  tempToken: string;
  errorMessage: string;
  confirmPasswordError: string;
  emailError: string;
  tooYoungError: string;
  successMessage: string;
  enteredEmail: string;
  enteredPassword: string;
  enteredConfirmPassword: string;
  enteredVerifyCode: string;
  enteredFirstName: string;
  enteredLastName: string;
  knowUsFrom: string;
  moreFeedback: string;
  isPasswordObscured: boolean;
  formSubmitted: boolean;
  isAgreed: boolean;
  selectedDay: string;
  selectedMonth: string;
  selectedYear: string;
  profileSrc: File | null | undefined;
  profilePreview: string | ArrayBuffer | null | undefined;
  alreadyResentCode: boolean;
}

interface SS {
  id: any;
}

export default class SignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  callSignupApiId = '';
  callResendCodeApiId = '';
  callCheckCodeApiId = '';
  callUpdateProfileApiId = '';
  callAutoLoginApiId = '';
  profileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.receive = this.receive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
    this.profileInputRef = React.createRef<HTMLInputElement>();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      currentStep: Step.EnterEmail, // Step.EnterEmail,
      isAgreed: false,
      token: '',
      tempToken: '',
      errorMessage: '',
      confirmPasswordError: '',
      emailError: '',
      tooYoungError: '',
      successMessage: '',
      enteredEmail: '',
      enteredFirstName: '',
      enteredLastName: '',
      enteredPassword: '',
      enteredConfirmPassword: '',
      enteredVerifyCode: '',
      knowUsFrom: '',
      isPasswordObscured: true,
      formSubmitted: false,
      selectedDay: '',
      selectedMonth: '',
      selectedYear: '',
      moreFeedback: '',
      profileSrc: null,
      profilePreview: null,
      alreadyResentCode: false,
    };
  }

  get isDisabled() {
    let isDisabled = true;
    const { currentStep, enteredEmail, isAgreed, enteredVerifyCode, enteredFirstName, enteredLastName, selectedDay, selectedMonth, selectedYear } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        isDisabled = enteredEmail.trim() === '' || isAgreed === false;
        break;
      case Step.EnterPassword:
        isDisabled = !this.metMin8Characters || !this.metAtLeastOneNumber || !this.metAtLeastOneSpecialCharacter;
        break;
      case Step.VerifyEmail:
        isDisabled = enteredVerifyCode.trim() === '';
        break;
      case Step.EnterRequiredFields:
        isDisabled = !enteredFirstName.trim() || !enteredLastName.trim() || !selectedDay || !selectedMonth || !selectedYear;
        break;
      case Step.EnterOptionalFields:
        isDisabled = false;
        break;
    }
    return isDisabled;
  }

  get submitBtnText() {
    let btnLabel = '';
    const { currentStep } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        btnLabel = 'Agree & Continue';
        break;
      case Step.EnterPassword:
      case Step.VerifyEmail:
        btnLabel = 'Submit';
        break;
      case Step.EnterRequiredFields:
        btnLabel = 'Continue';
        break;
      case Step.EnterOptionalFields:
        btnLabel = 'Submit';
        break;
      default:
        btnLabel = 'Submit';
        break;
    }
    return btnLabel;
  }

  get title() {
    let title = '';
    const { currentStep } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        title = `Welcome to <span class="brandname">Daydocker</span>`;
        break;
      case Step.EnterPassword:
        title = 'Choose a password to create your account';
        break;
      case Step.VerifyEmail:
        title = 'Verify your email address';
        break; 
      case Step.EnterRequiredFields:
        title = 'Create an account';
        break;
      case Step.EnterOptionalFields:
        title = 'Complete your profile';
        break;
    }
    return title;
  }

  get subTitle() {
    let subTitle = '';
    const { currentStep, enteredEmail } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        subTitle = `We'll send code to your email address.`;
        break;
      case Step.EnterPassword:
        subTitle = `Set a password for your account <strong>${enteredEmail}</strong>`;
        break;
      case Step.VerifyEmail:
        subTitle = `Enter the code that was sent to your <strong>${enteredEmail}</strong>`;
        break;
    }
    return subTitle;
  }

  get metMin8Characters() {
    return this.state.enteredPassword.trim().length >= 8;
  }

  get metAtLeastOneNumber() {
    return /\d/.test(this.state.enteredPassword.trim());
  }

  get metAtLeastOneSpecialCharacter() {
    return /[^A-Za-z0-9]/.test(this.state.enteredPassword.trim());
  }

  get confirmPasswordMatch() {
    return this.state.enteredPassword === this.state.enteredConfirmPassword;
  }

  get backOrNull() {
    const { currentStep } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        return null;
      case Step.EnterPassword:
        return this.handleOnBack;
      case Step.VerifyEmail:
        return null;
      case Step.EnterRequiredFields:
        return null;
      case Step.EnterOptionalFields:
        return this.handleOnBack;
    }
  }

  get daysArray() {
    const { selectedMonth, selectedYear } = this.state;
    return Array.from({ length: getDaysInMonth(+selectedMonth, +selectedYear) }, (____, iiii) => {
      const dayValue = iiii + 1;
      return { value: dayValue.toString().padStart(2, '0'), label: dayValue.toString().padStart(2, '0') };
    });
  }

  get is18orOlder() {
    const { selectedDay, selectedMonth, selectedYear } = this.state;
    if (selectedDay && selectedMonth && selectedYear) {
      return checkIfEighteenOrOlder(+selectedDay, +selectedMonth, +selectedYear);
    }
    return false;
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.continueFromLogin !== this.props.continueFromLogin) {
      const { email, password, tempToken, continueFromLogin } = this.props;
      const updatedState = {
        enteredEmail: email,
        enteredPassword: password,
        tempToken: tempToken,
      };
  
      if (continueFromLogin === Continue.VerifyEmail) {
        this.setState({ 
          ...updatedState, 
          currentStep: Step.VerifyEmail 
        }, this.resendVerifyCode);
      } else if (continueFromLogin === Continue.CompleteProfile) {
        this.setState({ 
          ...updatedState, 
          currentStep: Step.EnterRequiredFields 
        });
      }
    }
  }

  handleTermsCheckbox = () => this.setState(prevState => ({ isAgreed: !prevState.isAgreed }));

  handleClickTerms = () => {
    window.open('/terms-and-conditions', '_blank', 'noopener');
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
  };

  handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>, this.adjustDay);
  };

  adjustDay = () => {
    const { selectedDay, selectedMonth, selectedYear } = this.state;
    const daysInMonth = getDaysInMonth(parseInt(selectedMonth), parseInt(selectedYear));
    if (parseInt(selectedDay) > daysInMonth) {
      this.setState({ selectedDay: `${daysInMonth}` });
    }
  };

  handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.setState({ profileSrc: file });
      const reader = new FileReader();
      reader.onload = (event) => {
        this.setState({ profilePreview: event.target?.result });
      };
      reader.readAsDataURL(file);
    }
  };

  triggerProfileInput = () => {
    this.profileInputRef.current?.click();
  };

  handleOnBack = () => {
    this.setState(prevState => ({ 
      currentStep: prevState.currentStep - 1,
      errorMessage: '',
      successMessage: '',
    }));
  }

  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      isPasswordObscured: !prevState.isPasswordObscured
    }));
  }

  goToLogin = () => {
    this.props.goToLogin();
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Reset error state
    this.setState({ confirmPasswordError: ``, tooYoungError: `` });

    const { currentStep } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        if (!this.step1InputsOkay()) return;
        this.setState(prevState => ({ 
          currentStep: prevState.currentStep + 1,
          errorMessage: '',
          successMessage: '',
        }));
        break;
      case Step.EnterPassword:
        if (!this.confirmPasswordMatch) {
          this.setState({ confirmPasswordError: `The confirm password doesn't match` });
          return;
        }

        // Call sign up API
        this.doSignup();
        break;
      case Step.VerifyEmail:
        // Call check code API
        this.checkVerificationCode();
        break;
      case Step.EnterRequiredFields:
        if (!this.is18orOlder) {
          this.setState({ tooYoungError: configJSON.tooYoungError });
          return;
        }

        this.setState(prevState => ({ 
          currentStep: prevState.currentStep + 1,
          errorMessage: '',
          successMessage: '',
        }));
        break;
      case Step.EnterOptionalFields:
        if (this.state.isLoading === false) {
          // Update the user account
          this.updateProfile();
        }
        break;
    }
  }

  step1InputsOkay = (): boolean => {
    const { enteredEmail } = this.state;

    let emailError = '';

    if (!validateEmailFormat(enteredEmail)) {
      emailError = configJSON.errorEmail1;
    }

    this.setState({ emailError });

    if (!emailError) {
      this.setState({ emailError: '' });
      return true;
    }
    return false;
  }

  doSignup = () => {
    this.setState({ isLoading: true, errorMessage: '' });

    this.callSignupApiId = sendAPIRequest(
      `account/accounts`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          data: {
            type: "email_account",
            attributes: {
              email: this.state.enteredEmail,
              password: this.state.enteredPassword,
            },
          },
        }
      }
    )
  }

  resendVerifyCode = () => {
    if (this.state.isLoading === false) {
      this.setState({ isLoading: true, errorMessage: '' });

      this.callResendCodeApiId = sendAPIRequest(
        `send_email_verify`,
        {
          method: 'POST',
          headers: {
            'token': this.state.tempToken
          },
        }
      )
    }
  }

  checkVerificationCode = () => {
    if (this.state.isLoading === false) {
      this.setState({ isLoading: true, errorMessage: '' });

      this.callCheckCodeApiId = sendAPIRequest(
        `check_email_verify`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'token': this.state.tempToken
          },
          body: {
            otp: this.state.enteredVerifyCode
          },
        }
      )
    }
  }

  updateProfile = () => {
    this.setState({ isLoading: true, errorMessage: '' });

    const { 
      enteredFirstName, enteredLastName, selectedDay, selectedMonth, selectedYear,
      knowUsFrom, moreFeedback, profileSrc
    } = this.state;
    const formData = new FormData();
    formData.append('first_name', enteredFirstName);
    formData.append('last_name', enteredLastName);
    formData.append('date_of_birth', `${selectedDay}/${selectedMonth}/${selectedYear}`);
    formData.append('hear_us_from', knowUsFrom.trim());
    formData.append('some_feedback', moreFeedback.trim());
    if (profileSrc) {
      formData.append('profile_pic', profileSrc);
    }

    this.callUpdateProfileApiId = sendAPIRequest(
      `update_user`,
      {
        method: 'PUT',
        headers: {
          'token': this.state.tempToken,
        },
        body: formData,
      }
    )
  }

  automaticallyLogin = () => {
    this.setState({ isLoading: true, errorMessage: '' });

    this.callAutoLoginApiId = sendAPIRequest(
      `/login/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          data: {
            attributes: {
              password: this.state.enteredPassword,
              email: this.state.enteredEmail,
            },
            type: "email_account",
          },
        }
      }
    )
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callSignupApiId]: this.handleSignupResponse,
      [this.callResendCodeApiId]: this.handleResendVerifyCodeResponse,
      [this.callCheckCodeApiId]: this.handleCheckVerifyCodeResponse,
      [this.callUpdateProfileApiId]: this.handleUpdateProfileResponse,
      [this.callAutoLoginApiId]: this.handleAutoLoginResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });

    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      const errors = possibleErrors as ErrorType;
      if (Array.isArray(errors)) {
        const errorValues = Object.values(errors[0]);
        const errorMsg = errorValues.length > 0 ? errorValues[0]: 'Something went wrong';
        this.setState({ errorMessage: errorMsg, successMessage: '' });

        // if the error string matches "already taken"
        if (errorMsg.toLowerCase().includes('you already have') || errorMsg.toLowerCase().includes('email')) {
          // Go back to step 1 and show the error
          this.setState(prevState => ({ 
            currentStep: prevState.currentStep - 1,
            emailError: errorMsg,
            errorMessage: '',
            successMessage: '',
          }));
        }
      } else {
        this.setState({ 
          errorMessage: typeof errors === 'string' ? errors : 'Something went wrong',
          successMessage: '',
        });
      }
      return true; // Indicates that there was an error
    }

    return false; // Indicates that there was no error
  }

  handleSignupResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState({ errorMessage: '' });

    const response = responseJSON as { meta: { token: string; } };
    setStorageData('tempToken', response.meta.token);
    this.setState({ tempToken: response.meta.token });

    // Call request to send verification code API
    this.resendVerifyCode();

    this.setState(prevState => ({ 
      currentStep: prevState.currentStep + 1,
      errorMessage: '',
      successMessage: '',
    }));
  }

  handleResendVerifyCodeResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState(prevState => ({
      errorMessage: '', 
      successMessage: prevState.alreadyResentCode ? 'New code has been sent successfully.' : '',
      alreadyResentCode: true,
    }));
  }

  handleCheckVerifyCodeResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState(prevState => ({ 
      currentStep: prevState.currentStep + 1,
      errorMessage: '',
      successMessage: '',
    }));
  }

  handleUpdateProfileResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState({
      errorMessage: '',
      successMessage: '',
    });

    // Log the user in to get the real-auth token
    this.automaticallyLogin();
  }

  handleAutoLoginResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState({
      errorMessage: '',
      successMessage: '',
    });

    const response = responseJSON as LoginResponseType;

    removeStorageData('tempToken');
    setStorageData('token', response.meta.token);
    this.props.onClose();
    this.props.navigation.navigate('LandingPage');
  }

}

// Customizable Area End