// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import PersonOutlineRoundedIcon from "@material-ui/icons/PersonOutlineRounded";

import CalendarController, {
  Props,
  configJSON,
  assets,
  ILakeListItem,
} from "./CalendarController.web";
import { reservationTheme } from "./ReservationList.web";
import CalendarModal from "./CalendarModal.web";
import { format } from "date-fns";
import GenericModal from "../../../components/src/GenericModal";
import Toast from "../../../components/src/Toast";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
      contrastText: "#fff",
    },
    secondary: {
      main: "#64748B",
    },
    error: {
      main: "#FF0000",
    },
  },
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
      },
      h6: {
        fontFamily: "Josefin Sans",
        fontSize: "1.2rem",
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: "1rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#0F172A",
        wordBreak: "break-word",
        whiteSpace: "break-spaces",
      },
      subtitle2: {
        fontFamily: "Outfit",
        fontSize: "14px",
        fontWeight: 400,
        color: "#0F172A",
        wordBreak: "break-word",
        whiteSpace: "break-spaces",
      },
      body1: {
        fontFamily: "Josefin Sans",
        fontSize: "1.1rem",
        fontWeight: 700,
      },
    },
    MuiDivider: {
      root: {
        margin: "30px 0",
      },
    },
    MuiButton: {
      outlined: {
        marginTop: "10px",
        borderRadius: "8px",
        padding: "16px",
      },
      outlinedPrimary: {
        fontFamily: "Outfit",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "capitalize",
      },
      contained: {
        marginTop: "10px",
        padding: "12px 16px",
        fontFamily: "Outfit",
        fontSize: "1rem",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "8px",
      },
    },
    MuiCard: {
      root: {
        padding: "10px 15px",
        margin: "10px 10px 10px 0",
      },
    },
    MuiTabs: {
      root: {
        borderRadius: "30px",
        padding: "5px",
        width: "100%",
        backgroundColor: "#F2F2F2",
      },
      indicator: {
        display: "none",
      },
    },
    MuiTab: {
      root: {
        textTransform: "capitalize",
        fontFamily: "Outfit",
        fontWeght: 700,
        fontSize: "1rem",
        minWidth: "50% !important",
      },
      textColorInherit: {
        color: "#64748B",
      },
    },
    ...reservationTheme,
  },
});

const getListName = (selectedDock: ILakeListItem) => {
  return selectedDock.listing_title ? `${selectedDock.listing_title}, ${selectedDock.address}` : "Not yet selected";
};

export default class Calendar extends CalendarController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      lakeListModal,
      events,
      isDateClicked,
      selectedDate,
      selectedEventDate,
      selectedEventDetails,
      blockedDates,
      selectedDock,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper data-test-id="calendarMainWrapper">
          <GenericModal
            data-test-id="lakeModal"
            open={lakeListModal}
            customStyles={{
              modal: {
                marginTop: "-20px",
              },
            }}
            onClose={this.handleCloseLakeModal}
          >
            <ThemeProvider theme={theme}>
              <Wrapper>{this.renderSelectLakeListModal()}</Wrapper>
            </ThemeProvider>
          </GenericModal>
          <CalendarModal
            selectedDock={selectedDock}
            isOpen={isDateClicked}
            onClose={this.handleCloseModal}
          />
          <Container maxWidth="lg">
            <Header
              role="host"
              isLoggedIn={true}
              navigation={this.props.navigation}
            />
            <Box className="inboxHeader">
              <Typography
                variant="h4"
                className="headerTitle padding20"
                data-test-id="titleText"
              >
                {configJSON.calendarTitleText}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                className="openLakeListPopup"
                data-test-id="openLakeListPopup"
                onClick={this.toggleLakeListPopup}
              >
                {configJSON.selectListingText}
              </Button>
            </Box>
            <Box className="bodyContent">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={8} lg={8}>
                  <FullCalendar
                    data-test-id="fullCalendar"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    selectable
                    weekends
                    firstDay={1}
                    height="650px"
                    headerToolbar={{
                      start: "prev",
                      center: "title",
                      end: "next",
                    }}
                    events={events}
                    ref={(calendar: FullCalendar) => {
                      this.calendarRef = calendar;
                    }}
                    dayHeaderContent={(header: {text: string}) => (
                      <Typography variant="h6">{header.text}</Typography>
                    )}
                    dayCellContent={(cellContent: {dayNumberText: string}) => (
                      <Typography variant="subtitle1">
                        {cellContent.dayNumberText}
                      </Typography>
                    )}
                    dayCellClassNames={(cellClass: {date: Date}) => {
                      const cellDate = format(new Date(cellClass.date), "yyyy-MM-dd");
                      const isSameDate =
                        selectedDate &&
                        format(new Date(selectedDate), "yyyy-MM-dd") === cellDate;
                      const isBlockedDate = blockedDates
                        .map((blockDate) => format(new Date(blockDate), "yyyy-MM-dd"))
                        .includes(cellDate);
                      if (isSameDate && !isBlockedDate) {
                        return "selectedDate";
                      } else if (isSameDate) {
                        return "selectedDate blockedDate";
                      } else if (isBlockedDate) {
                        return "blockedDate";
                      }
                      return "";
                    }}
                    eventContent={(eventInfo: { event: {
                      start: string;
                    } }) => {
                      if (eventInfo.event.start) {
                        const cellDate = format(new Date(eventInfo.event.start), "yyyy-MM-dd");
                        return (
                          <FiberManualRecordIcon
                            color={
                              blockedDates
                                .map((blockDate) => format(new Date(blockDate), "yyyy-MM-dd"))
                                .includes(cellDate)
                                ? "disabled"
                                : "primary"
                            }
                            style={{ fontSize: "15px" }}
                          />
                        );
                      }
                    }}
                    dateClick={(info: {date:Date}) => this.handleDateClick(info)}
                    datesSet={() => {
                      const currentDate = this.calendarRef?.getApi().getDate() || new Date();
                      const currentMonth = new Date(currentDate).getMonth() + 1;
                      const currentYear = new Date(currentDate).getFullYear();
                      this.setState({ currentMonth, currentYear, selectedDate: null });                        
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography
                    variant="h6"
                    className="headerTitle smallScreenPadding"
                  >
                    {configJSON.selectedDockText}
                  </Typography>
                  <Typography className="headerTitle smallScreenPadding padding10">
                    {getListName(selectedDock)}
                  </Typography>
                  <Divider />
                  {selectedEventDate ? (
                    <>
                      <Typography
                        variant="h6"
                        className="headerTitle smallScreenPadding"
                        data-test-id="eventDetailsTitle"
                      >
                        {`Booking for ${selectedEventDate.toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }
                        )}`}
                      </Typography>
                      <Box className="eventGrid">
                        <Box>
                          <Card>
                            <CardContent>
                              <Typography
                                variant="subtitle1"
                                className="fontWeight600"
                              >
                                {selectedEventDetails.title}
                              </Typography>
                              <Typography variant="subtitle2">
                                {selectedEventDetails.address}
                              </Typography>
                              <Box className="flex padding20">
                                <img
                                  src={selectedEventDetails.image || assets.calendarDetailIcon}
                                  alt="Austin Lake"
                                  className="eventDetailIcon"
                                  loading="lazy"
                                />
                                <Box className="flex flexColumn">
                                  <Box className="flex">
                                    <CalendarTodayRoundedIcon color="secondary" />
                                    <Typography variant="subtitle1">
                                      
                                      {format(new Date(selectedEventDetails.startDate), "MM/dd/yyyy")}-
                                      {format(new Date(selectedEventDetails.endDate), "MM/dd/yyyy")}
                                    </Typography>
                                  </Box>
                                  <Box className="flex">
                                    <PersonOutlineRoundedIcon color="secondary" />
                                    <Typography variant="subtitle1">
                                      {selectedEventDetails.guests} guests
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="subtitle2"
                                    className=" fontWeight600"
                                  >
                                    Booked by {selectedEventDetails.bookedBy}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  border: "1px solid #E2E8F0",
                                  margin: "8px 0",
                                }}
                              />
                              <Box className="flexItems">
                                <Typography variant="subtitle2">
                                  {configJSON.basePayText}
                                </Typography>
                                <Typography variant="subtitle2">${selectedEventDetails.price_details.base_price}</Typography>
                              </Box>
                              <Box className="flexItems">
                                <Typography variant="subtitle2">
                                  {configJSON.yourEarningsLabel}
                                </Typography>
                                <Typography variant="subtitle2">${selectedEventDetails.price_details.your_earnings}</Typography>
                              </Box>
                              <Box className="flexItems">
                                <Typography variant="subtitle2">
                                  {configJSON.daydockerGuestTotalLabel}
                                </Typography>
                                <Typography variant="subtitle2">${selectedEventDetails.price_details.guest_total}</Typography>
                              </Box>
                              <Box className="flexItems padding10">
                                <Typography
                                  variant="subtitle1"
                                  className="fontWeight600"
                                >
                                  {configJSON.totalPayLabel}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  className="fontWeight600"
                                >
                                  ${selectedEventDetails.price_details.total_pay}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                    </Box>
                  </>
                  ) : (
                    <Box>
                      <Typography
                        variant="h6"
                        className="headerTitle smallScreenPadding"
                        data-test-id="titleText"
                      >
                        {configJSON.pricingText}
                      </Typography>
                      <Box className="borderedBox">
                        <Box className="flexItems">
                          <Typography variant="h6">{configJSON.basePriceText}</Typography>
                          <Box className="flex">
                            <Typography variant="h6">${selectedDock.base_price}</Typography>
                            <Typography variant="subtitle2" style={{ color: "#94A3B8" }}>{configJSON.dayText}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Typography
                        variant="h6"
                        className="headerTitle"
                        data-test-id="titleText"
                      >
                        {configJSON.wantToMakeMoneyText}
                      </Typography>
                      {
                        this.state.noDockErrorMsg && 
                          <Toast 
                            key={new Date().valueOf()}
                            message={this.state.noDockErrorMsg} />
                      }
                      <Button
                        data-test-id="customPriceButton"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        startIcon={<AddRoundedIcon />}
                        onClick={this.handleCustomPriceButtonClick}
                      >
                        {configJSON.customPriceButtonText}
                      </Button>
                      <Divider />
                      <Typography variant="h6" className="headerTitle">
                        {configJSON.availabityText}
                      </Typography>
                      <Typography variant="subtitle1">
                        {configJSON.availabityHelperText}
                      </Typography>
                      {this.renderAvailabilityBox()}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .lakeListTitle": {
    marginBottom: "10px",
  },
  "& .padding10": {
    padding: "8px 0 0 0",
  },
  "& .padding20": {
    padding: "20px 0 10px 0",
  },
  "& .fontWeight600": {
    fontWeight: 600,
  },
  "& .flexItems": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .eventDetailIcon": {
    width: "88px",
    height: "88px",
    borderRadius: "18px",
    objectFit: "cover",
  },
  "& .selectedTab": {
    border: "1px solid #4F9FF8",
    borderRadius: "30px",
    background: "#EDF5FE",
  },
  "& .fc-toolbar": {
    marginBottom: "1rem",
  },
  "& .fc-toolbar-title": {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Josefin Sans",
  },
  "& .fc-button": {
    background: "none",
    color: "#94A3B8",
    border: "none",
    borderRadius: "50% !important",
    padding: "5px",
    "&:hover": {
      background: "#EDF5FE",
      color: "#94A3B8",
      border: "none",
    },
    "&:focus": {
      background: "#EDF5FE !important",
      color: "#94A3B8 !important",
      border: "none !important",
      boxShadow: "none !important",
    },
  },
  "& .fc table": {
    border: "none !important",
  },
  "& .fc td": {
    verticalAlign: "bottom",
  },
  "& .fc-highlight": {
    backgroundColor: "transparent",
  },
  "& .fc-daygrid-day": {
    cursor: "pointer",
    borderWidth: "0 1 1 1",
  },
  "& .fc-day-today": {
    backgroundColor: "transparent !important",
  },
  "& .fc-daygrid-day-top": {
    justifyContent: "center",
    alignItems: "flex-end",
  },
  "& .fc-daygrid-day-events": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .fc-h-event": {
    border: "none",
    background: "transparent",
  },
  "& .selectedDate": {
    border: "2px solid #4F9FF8 !important",
  },
  "& .blockedDate": {
    color: "black !important",
    textDecoration: "line-through",
    background: "#AAA4A430 !important",
  },
  "& .bodyContent": {
    padding: "25px 0 40px 0",
  },
  "& .inboxHeader": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .borderedBox": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "10px",
    padding: "20px 30px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  "& .flex": {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  "& .flexColumn": {
    flexDirection: "column",
    gap: 2,
    alignItems: "flex-start",
  },
  "& .openLakeListPopup": {
    padding: "6px 16px",
    marginTop: 0,
  },
  "& .confirmBtn": {
    marginTop: "15px",
    padding: "8px 16px",
  },
  "& .eventGrid": {
    height: "730px",
    overflow: "auto",
  },
  [theme.breakpoints.down(960)]: {
    "& .smallScreenPadding": {
      paddingTop: "30px",
    },
  },
  [theme.breakpoints.down(840)]: {
    "& .headerTitle": {
      fontSize: "1.3rem",
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .inboxHeader": {
      paddingTop: "10px",
    },
    "& .headerTitle": {
      fontSize: "1.2rem",
    },
  },
}));

// Customizable Area End
